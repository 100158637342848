// This is a deprecated version of the sidebarApplicationDetail.tsx file in the original codebase.
// It displays application data based on submission data in question/answer format.
// See sidebarApplicationDetail.tsx for the updated version.

import React, { FC } from "react";
import { ApplicationDetail } from "../../models/applications/applicationInterfaces";
import { formatMoney, formatDate } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { selectMyUser } from "../../models/users/myUserSlice";
import { capitalizeFirstLetter } from "../../utils/formatting";

interface Props {
  application: ApplicationDetail;
}
export const SidebarApplicationDetailOld: FC<Props> = ({ application }) => {
  // Hash all characters in a String through a specified length
  const hashString = (value: string, minusLength: number = 0) => {
    let hashedString = "";
    for (let i = 0; i < value.length; i++) {
      if (i < value.length - minusLength) {
        hashedString = hashedString + (value[i] !== "-" ? "X" : "-");
      } else {
        hashedString = hashedString + value[i];
      }
    }
    return hashedString;
  };

  const displayValue = (key: string, value: any) => {
    // Check values to see if booleans are used and return checkmarks instead.
    switch (value) {
      case true:
      case "true":
        return "Yes";
      case false:
      case "false":
        return "No";
      // Format values based upon specific keys or default to returning as normal.
      default:
        const lowerCaseKey = key.toLowerCase();
        switch (key) {
          // Hash social security numbers.
          case "ssn":
          case "social security number":
          case "What is your social security number?":
          case "stripeVerificationSessionId":
            return <span>{hashString(value, 4)}</span>;
          default:
            // Add dollars to financial keys.
            if (
              lowerCaseKey.includes("income") ||
              lowerCaseKey.includes("amount") ||
              lowerCaseKey.includes("deposit") ||
              lowerCaseKey.includes("payment") ||
              lowerCaseKey.includes("value") ||
              lowerCaseKey.includes("savings") ||
              lowerCaseKey.includes("much")
            ) {
              if (lowerCaseKey.includes("sources of")) {
                break;
              }
              if (typeof value !== "boolean")
                return <span>{formatMoney(value)}</span>;
            }
            // Else return value as entered.
            return <span>{capitalizeFirstLetter(value)}</span>;
        }
    }
  };

  const user = useSelector(selectMyUser);

  const excludedQuestions = [
    "application id",
    "social security number",
    "what’s your full name?",
    "form id",
    "application received",
  ];
  return (
    <div className="px-4">
      <h6 className="h3 mb-0 text-center text-lg-left mt-2">
        Application Details
      </h6>
      <div className="sidebar-sub-section mt-2">
        <span className="meta text-uppercase">Application Number</span>
        <br />
        <span># {application.id}</span>
      </div>
      {/* Display Cotribute Database ID if user is admin */}
      {user?.isAdminPortalUser && (
        <div className="sidebar-sub-section mt-2">
          <span className="meta text-uppercase">Application ID</span>
          <br />
          <span>{application.metadata.id.answer}</span>
        </div>
      )}
      {application.created_at && (
        <div className="sidebar-sub-section mt-2">
          <span className="meta text-uppercase">Application Received</span>
          <br />
          <span>{formatDate(application.created_at)}</span>
        </div>
      )}
      <div className="sidebar-sub-section mt-2">
        <span className="meta text-uppercase">Applicant Name</span>
        <br />
        <span>
          {capitalizeFirstLetter(application.user.firstName)}{" "}
          {capitalizeFirstLetter(application.user.lastName)}
        </span>
      </div>
      {/* Display application's email if entered */}
      {application.user.email && (
        <div className="sidebar-sub-section">
          <span className="meta text-uppercase">Email</span>
          <br />
          <span>{application.user.email}</span>
        </div>
      )}
      {/* Display applicant's phone number if entered */}
      {application.user.phone && (
        <div className="sidebar-sub-section">
          <span className="meta text-uppercase">Phone Number</span>
          <br />
          <span>{application.user.phone}</span>
        </div>
      )}
      <div className="sidebar-sub-section">
        <span className="meta text-uppercase">Workflow</span>
        <br />
        <span>{application.category}</span>
      </div>
      {application.description && (
        <div className="sidebar-sub-section">
          <span className="meta text-uppercase">Description</span>
          <br />
          <span>{application.description}</span>
        </div>
      )}
      {/* Display urgentNeed if application has one */}
      {application.urgentNeed && (
        <div className="sidebar-sub-section">
          <span className="meta text-uppercase">Urgent</span>
          <br />
          <span>{application.urgentNeed ? "Yes" : "No"}</span>
        </div>
      )}
      {/* Display amount requested if applicable */}
      {application.amountRequested ? (
        <div className="sidebar-sub-section">
          <span className="meta text-uppercase">Amount Requested</span>
          <br />
          <span>{formatMoney(application.amountRequested)}</span>
        </div>
      ) : (
        ""
      )}
      {/* Map the remaining questions and answers in the column */}
      {Object.keys(application.metadata).map((k) => {
        const item = application.metadata[k];
        return (
          !excludedQuestions.includes(item.question.toLowerCase()) &&
          !item.question.toLowerCase().includes("account number") && (
            <div className="sidebar-sub-section" key={k}>
              <span className="meta text-uppercase">{item.question}</span>
              <br />
              <span>{displayValue(item.question, item.answer)}</span>
            </div>
          )
        );
      })}
    </div>
  );
};
