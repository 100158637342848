import React, { FC, useState, useEffect, useMemo } from "react";
import {
  DecisionStatus,
  FinancialApplication,
} from "../../models/applications/applicationInterfaces";
import { SidebarApplicationDetail } from "./sidebarApplicationDetail";
import { SidebarApplicationDetailOld } from "./sidebarApplicationDetailOld";
import { statusToTitle } from "../../utils/helpers";
import DocSwapModal from "../../pages/applications/docSwapModal";
import { DenyModal } from "../../pages/applications/denyModal";
import { useSelector, useDispatch } from "react-redux";
import { selectMyUser } from "../../models/users/myUserSlice";
import api from "../../utils/api";
import {
  changeAssignedAdmin,
  changeDecisionStatus,
  selectApplicationDetail,
} from "../../models/applications/applicationDetailSlice";
import { systemMessageSlice } from "../../models/systemMessageSlice";
import { selectOrg } from "../../models/organization/organizationSlice";
import {
  approveApplication,
  getApplicationDetail,
} from "../../models/applications/applicationDetailSlice";
import {
  FileEarmarkTextFill,
  ArrowRepeat,
  ListTask,
  BriefcaseFill,
  CheckCircleFill,
  FolderFill,
  XOctagonFill,
  Archive,
  ArchiveFill,
  PersonFill,
} from "react-bootstrap-icons";
import {
  OnboardingApplication,
  Flow,
} from "../../models/onboardingApplication/onboardingApplicationInterfaces";
import { capitalizeFirstLetter } from "../../utils/formatting";
import SmallSpinner from "../smallSpinner";

interface Props {
  availableDecisionStatuses?: DecisionStatus[];
  decisionStatus?: DecisionStatus;
  retrieveData?: Function;
  tasks?: any;
  decisionsLocked?: boolean;
  onboardingApplicationData: OnboardingApplication;
  flowData: Flow;
  financialApplication?: FinancialApplication;
}

interface StatusChangeAction {
  status: string;
  onClick: () => Promise<any>;
}

interface TaskTemplate {
  key: string;
  tooltip_title: string;
  tooltip_description: string;
  link: string;
  document_required: boolean;
  is_bill: boolean;
  title: string;
  questions: Array<{
    prompt: string;
    response: string;
    required: boolean;
  }>;
}

interface OrgTaskTemplates {
  [key: string]: Array<TaskTemplate>;
}

interface Admin {
  id: number;
  firstName: string;
  lastName: string;
}

export const AdminSidebar: FC<Props> = ({
  availableDecisionStatuses,
  decisionStatus,
  retrieveData,
  tasks,
  decisionsLocked,
  onboardingApplicationData,
  flowData,
  financialApplication,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [requestedAction, setRequestedAction] = useState(null as null | string);
  const [selectedDecisionUuid, setSelectedDecisionUuid] = useState<
    null | string
  >(null);
  const application = useSelector(selectApplicationDetail);
  const appDetails = useMemo(() => application.application, [application]);
  const permissions = useMemo(
    () => application.application?.permissions,
    [application]
  );

  // Not all applications have a default decision status right now, so this will
  // prevent an infinite spinner if there is no decision status.
  const [decisionStatusTimeout, setDecisionStatusTimeout] = useState(false);
  useEffect(() => {
    if (decisionStatusTimeout) return;

    const timeout = setTimeout(() => {
      setDecisionStatusTimeout(true);
    }, 1500);

    return () => clearTimeout(timeout);
  }, [decisionStatus]);

  const myUser = useSelector(selectMyUser);
  const dispatch = useDispatch();
  const [admins, setAdmins] = useState<Admin[]>([]);
  const organization = useSelector(selectOrg);
  const status = organization?.meta?.enableDecisioning
    ? decisionStatus?.decision
    : appDetails?.status;
  const [orgTaskTemplates, setOrgTaskTemplates] = useState<
    Array<[string, Array<TaskTemplate>]>
  >([]);
  const displayOnboardingApplicationResponses =
    organization?.meta?.displayOnboardingApplicationResponses;
  const GRAY = "#a2b0bc";

  // Applicant Information
  const applicantName = capitalizeFirstLetter(
    `${
      financialApplication?.applicant.firstName ?? appDetails?.user.firstName
    } ${financialApplication?.applicant.lastName ?? appDetails?.user.lastName}`
  );

  const coApplicantName = capitalizeFirstLetter(
    `${financialApplication?.coApplicant?.firstName ?? ""} ${
      financialApplication?.coApplicant?.lastName ?? ""
    }`
  );

  // Assets for SwapDoc functionality
  const [docSwapModalVisibility, setDocSwapModalVisibility] = useState(false);
  const [docSwapSelection, setDocSwapSelection] = useState(0);
  const swapDoc = async () => {
    try {
      await api.post(`/submissions/${appDetails?.id}/tasks/swap`, {
        submissionTaskTemplateSlug: orgTaskTemplates[docSwapSelection][0],
      });
      setDocSwapModalVisibility(false);
      retrieveData && retrieveData();
    } catch (e) {
      console.error(e);
    }
  };

  // Assets for Archival functionality
  const [archiveLoading, setArchiveLoading] = useState(false);
  const archive = async () => {
    try {
      setArchiveLoading(true);
      await api.post(`/submissions/${appDetails?.id}/archive`);
      appDetails && (await dispatch(getApplicationDetail(appDetails.id)));
    } catch (e) {
      console.error(e);
    }
    setArchiveLoading(false);
  };
  const unarchive = async () => {
    try {
      setArchiveLoading(true);
      await api.post(`/submissions/${appDetails?.id}/unarchive`);
      appDetails && (await dispatch(getApplicationDetail(appDetails.id)));
    } catch (e) {
      console.error(e);
    }
    setArchiveLoading(false);
  };

  // Convert a raw template name from JSON into something readable by the user.
  // EXAMPLE: "acquire_tasks__nutmeg_auto_loan" is displayed as "Acquire Tasks Nutmeg Auto Loan"
  const formatTemplateName = (key: string) => {
    const formattedName = key
      .toLowerCase() // make lowercase
      .split("_") // create array of all words
      .map((word) => {
        // capitalize first letter of all word
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" "); // join array words back into one string
    return formattedName.trim(); // Return the final name with all white space in the front removed.
  };

  useEffect(() => {
    // Retrieve all admins for the submission.
    api.get(`/submissions/${appDetails?.id}/admins`).then(({ data }) => {
      setAdmins(
        data.records.sort((a: Admin, b: Admin) =>
          a.firstName.localeCompare(b.firstName)
        )
      );
    });
  }, [appDetails?.id]);

  useEffect(() => {
    // Get the submission task templates for that organization
    api
      .get<OrgTaskTemplates>(
        `organizations/${organization.id}/submission-task-templates`
      )
      .then(({ data }) => {
        setOrgTaskTemplates(Object.entries(data));
      });
  }, []);

  // When an action is selected, render a modal that asks for confirmation.
  const renderConfirmationModal = () => {
    if (!showConfirmationModal || !requestedAction) return <></>;
    switch (requestedAction) {
      case "denied":
        return (
          <DenyModal
            onClose={() => setShowConfirmationModal(false)}
            show={true}
            newStatusUuid={selectedDecisionUuid}
          />
        );
      default:
        return null;
    }
  };

  // ---------------------------------------------------------------------------------------------------------------
  // LEGACY FUNCTIONS FOR WHEN DOCUMENT STATUS WAS USED IN PLACE OF DECISION STATUS
  // ---------------------------------------------------------------------------------------------------------------

  // For Acquire mode, when the reqested action is updated to "approved", run an "approve" api call.
  useEffect(() => {
    if (requestedAction === "approved") {
      approve({
        amountApproved: 0,
        amountCampaign: 0,
        amountMatch: 0,
        title: `BoA Approval - Application: ${appDetails?.id}`,
        description: "An approval generated for BoA",
      });
    }
  }, [requestedAction]);

  // For Acquire mode, this is the function that is run to approve an application in the useEffect above.
  async function approve(data: any) {
    const res: any = await dispatch(
      approveApplication({
        id: appDetails?.id as number,
        payload: data,
      })
    );
    if (res.error) {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message:
            "Error - Application Status Update Failed. Please try again later.",
          type: "danger",
        })
      );
    } else {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Application Status Has Been Updated!",
          type: "success",
        })
      );
    }
  }

  // Renders an icon to go with the corresponding document status
  const renderDocumentStatusIcon = (status: string) => {
    switch (status) {
      case "approved":
        return <CheckCircleFill />;
      case "denied":
        return <XOctagonFill />;
      case "ready_for_partner_review":
        return <BriefcaseFill />;
      case "items_needed":
        return <FolderFill />;
      default:
        return "";
    }
  };

  function getAvailableDocumentStatusChanges(): StatusChangeAction[] {
    return [
      // Define a list of possible actions (a collection)
      {
        status: "approved",
        onClick: async () => {
          setRequestedAction("approved");
          setShowConfirmationModal(true);
        },
      },
      {
        status: "denied",
        onClick: async () => {
          setRequestedAction("denied");
          setShowConfirmationModal(true);
        },
      },
    ];
  }

  function allowedToEdit(requestedStatus: string) {
    const currentApplicationStatus = appDetails?.status;
    // Disable under several conditions
    if (currentApplicationStatus === "approved") return false;
    if (currentApplicationStatus === "denied") return false;
    if (
      currentApplicationStatus === "items_needed" &&
      requestedStatus !== "denied"
    )
      return false;
    // Otherwise, disable if the admin does not have the permission to update the application status.
    return !!permissions?.includes("update-application-status");
  }

  // --------------------
  // RENDER
  // --------------------
  if (!appDetails) {
    return null;
  }
  return (
    <>
      {/* Document Swap Modal - Only shows when triggered by parent component */}
      <DocSwapModal
        show={docSwapModalVisibility}
        swapDoc={swapDoc}
        onClose={() => setDocSwapModalVisibility(false)}
        currentTasks={tasks}
        orgTaskTemplates={orgTaskTemplates}
        docSwapSelection={docSwapSelection}
      />

      {/* MAIN CONTENT -------------------------------------------------------- */}
      <div className="detail-sidebar d-flex flex-column">
        <div className="sidebar-section">
          {/* Application Status */}
          <div className="d-flex flex-column mt-4 px-3 py-2">
            {/* If CoApplicant, display "Primary Applicant" above the applicant's name. */}
            {financialApplication?.coApplicant && (
              <h1
                className="mb-0 fw-bold"
                style={{ fontSize: "13px", color: GRAY }}
              >
                PRIMARY APPLICANT
              </h1>
            )}
            <div className="h3 mb-0" style={{ fontWeight: 600 }}>
              {applicantName}
            </div>
            {/* If CoApplicant, display "Joint Applicant" and name */}
            {financialApplication?.coApplicant && (
              <div className="h5 mt-2 mb-4" style={{ fontWeight: 600 }}>
                <h1
                  className="mb-0 fw-bold"
                  style={{ fontSize: "13px", color: GRAY }}
                >
                  JOINT APPLICANT
                </h1>
                {coApplicantName}
              </div>
            )}
            <div className="h6" style={{ color: GRAY }}>
              {!status && !decisionStatusTimeout ? (
                <SmallSpinner />
              ) : status === "approved" ? (
                "APPLICATION COMPLETE"
              ) : (
                "APPLICATION IN PROGRESS"
              )}
            </div>
          </div>
        </div>

        <div className="sidebar-section pl-4">
          <div
            className="bg-white mr-4 p-3 border"
            style={{ borderRadius: "8px", borderColor: "#ededed" }}
          >
            {organization.meta?.enableDecisioning === true ? (
              <>
                {/* Decision Status Button */}
                <span className="" style={{ color: "#a2b0bc" }}>
                  Status:
                </span>
                {/* If the user has the permission to update the decision status, show a select dropdown. */}
                {!!permissions?.includes("update-application-status") ? (
                  decisionStatus?.uuid || decisionStatusTimeout ? (
                    <select
                      className="custom-select mb-3"
                      style={{ maxWidth: "95%" }}
                      value={decisionStatus?.uuid}
                      onChange={async (e) => {
                        const newStatusUuid = e.target.value;
                        const selectedOption =
                          e.target.options[e.target.selectedIndex];
                        const decision =
                          selectedOption.getAttribute("data-decision");
                        if (decision === "denied") {
                          setSelectedDecisionUuid(newStatusUuid);
                          setRequestedAction("denied");
                          setShowConfirmationModal(true);
                          return;
                        }
                        const res: any = await dispatch(
                          changeDecisionStatus({
                            newDecisionStatusUuid: newStatusUuid,
                          })
                        );
                        if (res.error) {
                          dispatch(
                            systemMessageSlice.actions.setMessage({
                              message:
                                "Error - Change Decision Failed. Please try again.",
                              type: "danger",
                            })
                          );
                        } else {
                          dispatch(
                            systemMessageSlice.actions.setMessage({
                              message: "Decision Changed Successfully!",
                              type: "success",
                            })
                          );
                        }
                      }}
                    >
                      {availableDecisionStatuses?.length &&
                        availableDecisionStatuses.map((decisionStatus) => {
                          return (
                            <option
                              value={decisionStatus.uuid}
                              key={decisionStatus.uuid}
                              disabled={decisionsLocked}
                              data-decision={decisionStatus.decision}
                              className={
                                decisionsLocked ? "disabled-option" : ""
                              }
                            >
                              {decisionStatus.title}
                            </option>
                          );
                        })}
                    </select>
                  ) : (
                    <SmallSpinner color={GRAY} className="mb-5" />
                  )
                ) : (
                  // If the user does not have permission to update the decision status, show the status as text instead.
                  <p className="font-weight-bold mb-0">
                    {decisionStatus?.title || statusToTitle[appDetails.status]}
                  </p>
                )}
              </>
            ) : (
              <>
                {/* Document Status Button (LEGACY) */}
                {status && (
                  <>
                    <span
                      className="font-weight-bold"
                      style={{ color: "#a2b0bc" }}
                    >
                      Status:
                    </span>
                    <div style={{ fontSize: "18px" }}>
                      {renderDocumentStatusIcon(status)}{" "}
                      <span className="font-italic">
                        {statusToTitle[status]}
                      </span>
                    </div>
                  </>
                )}

                <div className="dropdown">
                  <a
                    className="btn btn-primary btn-block d-flex align-items-center"
                    style={{ maxWidth: "95%" }}
                    href="javascript:void(0);"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-left flex-fill">
                      <ListTask
                        className="ml-2 mr-4"
                        style={{ fontSize: "30px" }}
                      />
                      Update Status
                    </div>
                    <span className="oi oi-caret-bottom"></span>
                  </a>

                  <div
                    className="dropdown-menu border-highlight"
                    aria-labelledby="dropdownMenuLink"
                  >
                    {getAvailableDocumentStatusChanges().map((action) => {
                      return (
                        <button
                          className="dropdown-item"
                          disabled={
                            status === action.status ||
                            !allowedToEdit(action.status)
                          }
                          onClick={action.onClick}
                        >
                          <span
                            className="mr-3 text-secondary"
                            style={{ fontSize: "24px" }}
                          >
                            {renderDocumentStatusIcon(action.status)}
                          </span>
                          {statusToTitle[action.status]}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </>
            )}

            {/* Document Swap Button */}
            {/* Set to 99 - Temporary disable */}
            {myUser?.isAdminPortalUser && orgTaskTemplates.length > 99 && (
              <>
                <div className="dropdown mt-2">
                  <a
                    className="btn btn-primary btn-block d-flex align-items-center"
                    href="javascript:void(0);"
                    style={{ maxWidth: "95%" }}
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-left flex-fill">
                      <ArrowRepeat
                        className="ml-2 mr-4"
                        style={{ fontSize: "30px" }}
                      />
                      Swap Documents
                    </div>

                    <span className="oi oi-caret-bottom"></span>
                  </a>
                  <div
                    className="dropdown-menu border-highlight"
                    aria-labelledby="dropdownMenuLink"
                  >
                    {orgTaskTemplates.map((template, key) => {
                      return (
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            setDocSwapModalVisibility(true);
                            setDocSwapSelection(key);
                          }}
                        >
                          <FileEarmarkTextFill
                            className="mr-3 text-secondary"
                            style={{ fontSize: "24px" }}
                          />
                          {formatTemplateName(template[0])}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </>
            )}

            {/* Archive/Unarchive Button */}
            {/* If the user has the permission to archive/unarchive the application, show the button. */}
            {!!permissions?.includes("archive-application") ? (
              <div className="mt-2">
                <button
                  className="btn btn-primary btn-block d-flex align-items-center"
                  style={{
                    maxWidth: "95%",
                    backgroundColor: "#6d6d6d",
                    borderColor: "#6d6d6d",
                  }}
                  onClick={() =>
                    appDetails?.archived ? unarchive() : archive()
                  }
                >
                  {/* Upon clicking button, instantly update displayed button while 
                the updated application details are fetched. */}
                  {appDetails?.archived ? (
                    !archiveLoading ? (
                      <div>
                        <ArchiveFill
                          className="ml-2 mr-4"
                          style={{ fontSize: "30px" }}
                        />
                        Unarchive
                      </div>
                    ) : (
                      <div>
                        <Archive
                          className="ml-2 mr-4"
                          style={{ fontSize: "30px" }}
                        />
                        Archive
                      </div>
                    )
                  ) : !archiveLoading ? (
                    <div>
                      <Archive
                        className="ml-2 mr-4"
                        style={{ fontSize: "30px" }}
                      />
                      Archive
                    </div>
                  ) : (
                    <div>
                      <ArchiveFill
                        className="ml-2 mr-4"
                        style={{ fontSize: "30px" }}
                      />
                      Unarchive
                    </div>
                  )}
                </button>
              </div>
            ) : (
              // If the user does not have permission to archive/unarchive the application and the applicaiton is archived, show the archived status as text instead.
              appDetails.archived && (
                <div className="mt-3" style={{ color: "#a2b0bc" }}>
                  <ArchiveFill /> Archived
                </div>
              )
            )}
          </div>
          {/* Assignment Button */}
          {/* // If the user has the permission to update the assigned admin, show the select dropdown. */}
          {!!permissions?.includes("update-application-assignee") ? (
            <div className="mt-4">
              <span className="font-weight-bold"> Assigned Team Member:</span>
              {admins.length ? (
                <select
                  className="custom-select mb-3"
                  style={{ maxWidth: "95%", borderColor: "#dee2e6" }}
                  value={appDetails.benevolenceAdmin.id}
                  onChange={async (e) => {
                    const adminId = e.target.value;

                    const res: any = await dispatch(
                      changeAssignedAdmin(+adminId)
                    );
                    if (res.error) {
                      dispatch(
                        systemMessageSlice.actions.setMessage({
                          message:
                            "Error - Reassign Team Member Failed. Please try again.",
                          type: "danger",
                        })
                      );
                    } else {
                      dispatch(
                        systemMessageSlice.actions.setMessage({
                          message: "Reassign Team Member Successfully!",
                          type: "success",
                        })
                      );
                    }
                  }}
                >
                  {admins.map(
                    (user: {
                      id: number;
                      firstName: string;
                      lastName: string;
                    }) => {
                      return (
                        <option key={user.id} value={user.id}>
                          {user.firstName} {user.lastName}
                        </option>
                      );
                    }
                  )}
                </select>
              ) : (
                <SmallSpinner color={GRAY} className="mb-5" />
              )}
            </div>
          ) : (
            // If the user does not have permission to update the assigned admin, show the assigned admin as text instead.
            <div className="mt-3 mb-3">
              <span className="font-weight-bold"> Assigned Team Member:</span>
              <div>
                <PersonFill className="mr-2" />
                {appDetails.benevolenceAdmin.firstName}{" "}
                {appDetails.benevolenceAdmin.lastName}
              </div>
            </div>
          )}
        </div>

        <div className="sidebar-section">
          {/* Display application information either in the same format stored in the Acquire Review Step (Onboarding Application Responses), 
              or in the old method (submission question/answer). */}
          {displayOnboardingApplicationResponses ? (
            <SidebarApplicationDetail
              application={appDetails}
              onboardingApplicationData={onboardingApplicationData}
              flowData={flowData}
            />
          ) : (
            <SidebarApplicationDetailOld application={appDetails} />
          )}
          <div className="px-4">
            {Object.entries(onboardingApplicationData.utmParams || {}).map(
              ([key, value]) => (
                <div key={key} className="response-step-field mt-2">
                  <span className="meta text-uppercase">{key}</span>
                  <br />
                  <span>{value?.toString() || "-"}</span>
                </div>
              )
            )}
          </div>
        </div>
        {renderConfirmationModal()}
      </div>
    </>
  );
};
